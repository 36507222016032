import { useMemo } from 'react'

import { resolveMedia } from '@lib/mediaResolver'
import { globals } from '@lib/messages/protobuf'
import { IntoUrl } from '@models/IntoUrl'

export function useIsVideo(url?: IntoUrl): boolean {
  return useMemo(() => {
    if (!url) return false

    const { resolvedMediaType } = resolveMedia(url)
    const { AUDIO, VIDEO, GIF } = globals.MediaType
    return !!resolvedMediaType && [VIDEO, AUDIO, GIF].includes(resolvedMediaType)
  }, [url])
}
