const DEFAULT_SIZE = 24

const IconEmail = ({ size = DEFAULT_SIZE }: { size?: number }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 24 24" fill="none">
    <path
      d="M21.6333 17.9815C21.6 17.9815 21.5333 18.0148 21.5 18.0148H2.5C2.46667 18.0148 2.4 18.0148 2.36667 17.9815L8.3 12.0481L9.56667 13.3481C10.9333 14.7481 13.1 14.7481 14.4667 13.3481L15.7333 12.0481L21.6333 17.9815ZM22 17.3481V5.58148C22 5.51481 22 5.44815 21.9667 5.41481C21.9333 5.48148 16.0667 11.5481 16.0667 11.5481L22 17.4815C22 17.4148 22 17.3815 22 17.3481ZM2 17.3815V5.64815C2 5.58148 2 5.51481 2.03333 5.48148C2.06667 5.54815 7.93333 11.6148 7.93333 11.6148L2.03333 17.5148C2 17.4815 2 17.4148 2 17.3815ZM21.6667 5.01481L14.1 12.7481C12.9333 13.9148 11.0667 13.9148 9.93333 12.7481L2.36667 5.01481C2.33333 4.98148 21.6667 5.01481 21.6667 5.01481Z"
      fill="currentColor"
    />
  </svg>
)

export default IconEmail
