const DEFAULT_SIZE = 24

const IconAuthTwitter = ({ size = DEFAULT_SIZE }: { size?: number }) => (
  <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_6315_7099)">
      <g clipPath="url(#clip1_6315_7099)">
        <path
          d="M14.469 10.1571L23.2069 0H21.1363L13.5491 8.81931L7.4893 0H0.5L9.66366 13.3364L0.5 23.9877H2.57073L10.583 14.6742L16.9826 23.9877H23.9719L14.4684 10.1571H14.469ZM11.6328 13.4538L10.7043 12.1258L3.31684 1.55881H6.49736L12.4592 10.0867L13.3876 11.4147L21.1373 22.4998H17.9567L11.6328 13.4544V13.4538Z"
          fill="currentColor"
        />
      </g>
    </g>
  </svg>
)

export default IconAuthTwitter
