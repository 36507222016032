import { FastAverageColor } from 'fast-average-color'
import { useEffect } from 'react'

import { IntoUrl } from '@models/IntoUrl'
import { selectContrastBackgroundColor, setContrastBackgroundColor } from '@redux/slices/mobileSlice'
import { useAppDispatch, useAppSelector } from '@redux/store/store'

const fac = new FastAverageColor()

export const ContrastContainer = ({
  children,
  className,
  url,
  ...otherProps
}: {
  children: React.ReactNode
  className?: string
  url?: IntoUrl
} & React.HTMLAttributes<HTMLDivElement>) => {
  const dispatch = useAppDispatch()
  const contrastBackgroundColor = useAppSelector(state => selectContrastBackgroundColor(state, url?.url_id || ''))
  const backgroundImageUrl = url?.tiny_thumbnail || url?.thumbnail || ''

  useEffect(() => {
    if (contrastBackgroundColor) return
    fac
      .getColorAsync(backgroundImageUrl, { crossOrigin: 'anonymous' })
      .then(({ isDark }) => {
        dispatch(setContrastBackgroundColor({ urlId: url?.url_id || '', color: isDark ? 'light' : 'dark' }))
      })
      .catch(() => {})
  }, [backgroundImageUrl, dispatch, url?.url_id, contrastBackgroundColor])

  return (
    <div
      className={`${className} transition-colors duration-300 ${contrastBackgroundColor === 'dark' ? 'bg-black/20' : 'bg-white/20'}`}
      {...otherProps}
    >
      {children}
    </div>
  )
}

export default ContrastContainer
