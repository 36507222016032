import React, { useCallback, useEffect, useRef, useState } from 'react'
import ReactPlayer from 'react-player'

import { useMobileLayout } from '@hooks/useMobileLayout'
import { useVideoPlayerControls } from '@hooks/useVideoPlayerControls'
import { useVideoPlayerEvents } from '@hooks/useVideoPlayerEvents'
import { useVideoPlayerContext } from '@pages/url/components/VideoPlayerContext'

interface ProgressBarProps {
  playerRef: React.RefObject<ReactPlayer>
}

const ProgressBar: React.FC<ProgressBarProps> = ({ playerRef }) => {
  const {
    playing,
    played,
    duration,
    seeking,
    setSeeking,
    isGrabbing,
    setIsGrabbing,
    setWasPlayingBeforeSeeking,
    wasPlayingBeforeSeeking,
    setDidPauseForDrag,
    didPauseForDrag,
    setPlayed,
    setCurrentTime,
  } = useVideoPlayerContext()
  const { onPlayPause, onSeekMouseDown } = useVideoPlayerControls(playerRef)
  const shouldRenderMobile = useMobileLayout()
  const progressBarRef = useRef<HTMLDivElement | null>(null)
  const { onInteraction } = useVideoPlayerEvents()
  const [isProgressBarHovered, setIsProgressBarHovered] = useState(false)
  const [, setIsTouchSeeking] = useState(false)
  // eslint-disable-next-line no-magic-numbers
  const progress = (played * 100).toFixed(2) + '%'

  const onSeekChange = useCallback(
    (newTime: number) => {
      const newPlayedValue = newTime / duration
      setPlayed(newPlayedValue)
      setCurrentTime(newTime)
      // Include any additional state updates or side effects here
    },
    [duration, setPlayed, setCurrentTime]
  )

  const onSeekMouseMove = useCallback(
    (event: MouseEvent) => {
      if (!seeking || !progressBarRef.current) return
      onInteraction()
      if (playing && !didPauseForDrag) {
        setDidPauseForDrag(true)
        setWasPlayingBeforeSeeking(true)
        onPlayPause()
      }
      const progressBar: HTMLElement = progressBarRef.current
      const rect = progressBar.getBoundingClientRect()
      const clickX = event.clientX - rect.left
      let newPlayedValue = clickX / rect.width

      // Clamp the value between 0 and 1
      newPlayedValue = Math.min(Math.max(newPlayedValue, 0), 1)

      const newTime = newPlayedValue * duration
      onSeekChange(newTime)

      if (playerRef.current) {
        if (Number.isFinite(duration) && duration > 0) {
          playerRef.current.seekTo(newTime / duration, 'fraction')
        } else {
          // Fallback to using newTime directly if duration is not valid.
          playerRef.current.seekTo(newTime, 'seconds')
        }
      }
    },
    [
      seeking,
      duration,
      onSeekChange,
      didPauseForDrag,
      playing,
      onPlayPause,
      setDidPauseForDrag,
      setWasPlayingBeforeSeeking,
      onInteraction,
      playerRef,
    ]
  )

  const onSeekTouchMove = useCallback(
    (event: React.TouchEvent<HTMLDivElement>) => {
      if (!progressBarRef.current) return
      event.stopPropagation()
      if (!shouldRenderMobile) event.preventDefault()
      onInteraction()
      if (playing && !didPauseForDrag) {
        setDidPauseForDrag(true)
        setWasPlayingBeforeSeeking(true)
        onPlayPause()
      }
      const progressBar: HTMLElement = progressBarRef.current
      const rect = progressBar.getBoundingClientRect()
      const touchX = event.touches[0].clientX - rect.left
      let newPlayedValue = touchX / rect.width

      // Clamp the value between 0 and 1
      newPlayedValue = Math.min(Math.max(newPlayedValue, 0), 1)

      const newTime = newPlayedValue * duration
      onSeekChange(newTime)

      if (playerRef.current) {
        if (Number.isFinite(duration) && duration > 0) {
          playerRef.current.seekTo(newTime / duration, 'fraction')
        } else {
          // Fallback to using newTime directly if duration is not valid.
          playerRef.current.seekTo(newTime, 'seconds')
        }
      }
    },
    [
      shouldRenderMobile,
      duration,
      onSeekChange,
      didPauseForDrag,
      playing,
      onPlayPause,
      setDidPauseForDrag,
      setWasPlayingBeforeSeeking,
      onInteraction,
      playerRef,
    ]
  )

  const handleProgressBarInteraction = useCallback(
    (event: React.MouseEvent<HTMLDivElement> | React.TouchEvent<HTMLDivElement>) => {
      event.stopPropagation()

      if (!progressBarRef.current) return
      onInteraction()
      const progressBar: HTMLElement = progressBarRef.current
      const rect = progressBar.getBoundingClientRect()
      let clickX

      if ('clientX' in event) {
        clickX = event.clientX - rect.left
      } else {
        clickX = event.changedTouches[0].clientX - rect.left
      }

      let newPlayedValue = clickX / rect.width
      // Clamp the value between 0 and 1
      newPlayedValue = Math.min(Math.max(newPlayedValue, 0), 1)
      const newTime = newPlayedValue * duration
      onSeekChange(newTime)
      if (didPauseForDrag) {
        setDidPauseForDrag(false)
      }

      if (wasPlayingBeforeSeeking) {
        setWasPlayingBeforeSeeking(false)
        onPlayPause()
      }
      setIsGrabbing(false)
      setSeeking(false)
      onSeekChange(newTime)

      if (playerRef.current) {
        if (Number.isFinite(duration) && duration > 0) {
          playerRef.current.seekTo(newTime / duration, 'fraction')
        } else {
          // Fallback to using newTime directly if duration is not valid.
          playerRef.current.seekTo(newTime, 'seconds')
        }
      }
    },
    [
      duration,
      onInteraction,
      didPauseForDrag,
      wasPlayingBeforeSeeking,
      onPlayPause,
      onSeekChange,
      playerRef,
      setDidPauseForDrag,
      setIsGrabbing,
      setSeeking,
      setWasPlayingBeforeSeeking,
    ]
  )

  const onSeekStart = useCallback(
    (event: React.MouseEvent<HTMLDivElement> | React.TouchEvent<HTMLDivElement>) => {
      event.stopPropagation()
      if ('touches' in event && !shouldRenderMobile) event.preventDefault()
      onInteraction()
      setSeeking(true)
      setIsGrabbing(true)
      onSeekMouseDown()
    },
    [shouldRenderMobile, onInteraction, setSeeking, setIsGrabbing, onSeekMouseDown]
  )

  const onSeekEnd = useCallback(
    (event: React.MouseEvent<HTMLDivElement> | React.TouchEvent<HTMLDivElement>) => {
      if ('touches' in event) {
        event.stopPropagation()
        event.preventDefault()
      }
      setIsTouchSeeking(false)
      setSeeking(false)
      setIsGrabbing(false)
      handleProgressBarInteraction(event)
    },
    [setIsTouchSeeking, setSeeking, setIsGrabbing, handleProgressBarInteraction]
  )

  useEffect(() => {
    const handleMouseMove = (event: MouseEvent) => {
      if (seeking && progressBarRef.current) onSeekMouseMove(event)
    }

    const handleMouseUp = (event: MouseEvent) => {
      if (seeking && progressBarRef.current) onSeekEnd(event as unknown as React.MouseEvent<HTMLDivElement>)
    }

    document.addEventListener('mousemove', handleMouseMove)
    document.addEventListener('mouseup', handleMouseUp)

    return () => {
      document.removeEventListener('mousemove', handleMouseMove)
      document.removeEventListener('mouseup', handleMouseUp)
    }
  }, [seeking, onSeekMouseMove, onSeekEnd])

  return (
    <div
      ref={progressBarRef}
      onClick={handleProgressBarInteraction}
      onMouseDown={onSeekStart}
      onTouchStart={onSeekStart}
      onTouchMove={onSeekTouchMove}
      onTouchEnd={onSeekEnd}
      onMouseEnter={() => setIsProgressBarHovered(true)}
      onMouseLeave={() => setIsProgressBarHovered(false)}
      className={`${
        shouldRenderMobile ? 'absolute -bottom-4 h-8 bg-transparent' : 'relative h-1 bg-white/20'
      } flex w-full cursor-pointer items-center`}
    >
      <div
        style={{ width: progress }}
        className={`absolute left-0 top-1/2 -translate-y-1/2 bg-white ${shouldRenderMobile ? 'h-[2px]' : 'h-1'}`}
      ></div>
      {(isGrabbing || isProgressBarHovered || seeking) && (
        <div
          style={{ left: `calc(${progress} - 8px)` }}
          className={`absolute top-1/2 size-4 -translate-y-1/2 rounded-full bg-white ${
            isGrabbing ? 'cursor-grabbing' : 'cursor-grab'
          }`}
        ></div>
      )}
    </div>
  )
}

export default ProgressBar
