import { AnimatePresence, motion } from 'framer-motion'
import React, { useCallback, useEffect, useRef, useState } from 'react'

import { useCurrentUserContext } from '@context/CurrentUserContext'
import IconPlay from '@icons/IconPlayVideo.svg'
import { Z_VIDEO_OVERLAY } from '@mobile/url/Url'
import { useVideoPlayerContext } from '@pages/url/components/VideoPlayerContext'
import { useCurrentUrlContext } from '@pages/url/CurrentUrlContext'
import { sendTrackContentPause } from '@tracking/events/trackContentPause'
import { sendTrackContentPlay } from '@tracking/events/trackContentPlay'

const SECOND_TO_MS = 1000

export const PlayPauseToggle: React.FC = () => {
  const { playing, setPlaying, duration, currentTime, seeking, played } = useVideoPlayerContext()
  const { currentUserID: userId } = useCurrentUserContext()
  const { currentUrl: url, perceivedMediaType } = useCurrentUrlContext()
  const [loops, setLoops] = useState(0)
  const previousPlayed = useRef(0)

  // Effect for loops.
  useEffect(() => {
    if (!seeking && played < previousPlayed.current) {
      setLoops((prev: number) => prev + 1)
    }
    previousPlayed.current = played
  }, [played, seeking])

  const handleTrackContentPlay = useCallback(() => {
    sendTrackContentPlay({
      userId,
      url,
      perceivedMediaType,
      contentTimeTotalMsec: duration * SECOND_TO_MS,
      replayCount: loops,
      timeOffsetMsec: currentTime * SECOND_TO_MS,
    })
  }, [userId, url, perceivedMediaType, duration, loops, currentTime])

  const handleTrackContentPause = useCallback(() => {
    sendTrackContentPause({
      userId,
      url,
      perceivedMediaType,
      contentTimeTotalMsec: duration * SECOND_TO_MS,
      replayCount: loops,
      timeOffsetMsec: currentTime * SECOND_TO_MS,
    })
  }, [userId, url, perceivedMediaType, duration, loops, currentTime])

  const onPlayPause = useCallback(() => {
    setPlaying(!playing)
  }, [playing, setPlaying])

  const onTogglePlayAndTrack = useCallback(() => {
    onPlayPause()

    if (!playing) {
      handleTrackContentPlay()
    } else {
      handleTrackContentPause()
    }
  }, [playing, handleTrackContentPlay, handleTrackContentPause, onPlayPause])

  const showPlayPauseButton = true

  return (
    <div
      className={`pointer-events-auto absolute left-0 top-0 ${playing ? 'z-40' : Z_VIDEO_OVERLAY} flex size-full items-center justify-center opacity-100`}
      onClick={onTogglePlayAndTrack}
    >
      {showPlayPauseButton && !playing && (
        <AnimatePresence>
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.3 }}
          >
            <div className="pointer-events-none absolute inset-0 flex size-full items-center justify-center bg-radial-fade-sm">
              <IconPlay className="size-20 text-white" />
            </div>
          </motion.div>
        </AnimatePresence>
      )}
    </div>
  )
}

export default PlayPauseToggle
