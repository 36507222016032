import { memo, useEffect, useMemo, useRef, useState } from 'react'
import { shallowEqual } from 'react-redux'

import { useGlobalContext } from '@context/GlobalContexts'
import { resolveMedia } from '@lib/mediaResolver'
import LoginSlide, { LOGIN_SLIDE_URL_ID } from '@mobile/LoginSlide'
import { FeedItem, keyForResource } from '@models/APIResource'
import { CurrentUrlContextProvider } from '@pages/url/CurrentUrlContext'
import {
  incrementPage,
  selectCurrentFeedItemKey,
  selectMobileFeed,
  setCurrentFeedItemKey,
} from '@redux/slices/feedSlice'
import { setIsAutoMuted } from '@redux/slices/videoSlice'
import { useAppDispatch, useAppSelector } from '@redux/store/store'
import Url from './url/Url'

const NEAR_END_THRESHOLD = 2
const VISIBLE_ITEMS_COUNT = 3
const ITEMS_BEFORE_CURRENT = 1

const VerticalFeed = ({ renderLoginSlide }: { renderLoginSlide?: boolean }) => {
  const dispatch = useAppDispatch()
  const containerRef = useRef<HTMLDivElement>(null)
  const itemRefs = useRef<(HTMLDivElement | null)[]>([])
  const { ios } = useGlobalContext().browserInfo

  const itemsRaw = useAppSelector(selectMobileFeed(['url', 'login']), shallowEqual)
  const items = useMemo(
    () => (renderLoginSlide ? [{ id: LOGIN_SLIDE_URL_ID, type: 'login' } as FeedItem, ...itemsRaw] : itemsRaw),
    [renderLoginSlide, itemsRaw]
  )
  const itemsRef = useRef(items)
  const currentFeedItemKey = useAppSelector(selectCurrentFeedItemKey)
  itemsRef.current = items

  useEffect(() => {
    if (!currentFeedItemKey) {
      if (renderLoginSlide) {
        dispatch(setCurrentFeedItemKey(LOGIN_SLIDE_URL_ID))
      } else {
        dispatch(setCurrentFeedItemKey(keyForResource(itemsRef.current[0])?.toString() || ''))
      }
    }
  }, [currentFeedItemKey, renderLoginSlide, dispatch])

  const initialIndex = Math.max(
    items.findIndex(item => keyForResource(item) === currentFeedItemKey),
    0
  )

  const [currentUrlIdx, setCurrentUrlIdx] = useState(initialIndex)
  const isUserScrollingRef = useRef(false)

  // Determine the window of visible items.
  const [startIndex, setStartIndex] = useState(Math.max(0, currentUrlIdx - ITEMS_BEFORE_CURRENT))
  const visibleItems = useMemo(() => {
    return items.slice(startIndex, startIndex + VISIBLE_ITEMS_COUNT)
  }, [items, startIndex])

  // Update `startIndex` when `currentUrlIdx` changes.
  useEffect(() => {
    const newStartIndex = Math.max(0, currentUrlIdx - ITEMS_BEFORE_CURRENT)
    if (newStartIndex !== startIndex) {
      setStartIndex(newStartIndex)
    }
  }, [currentUrlIdx, startIndex])

  // Handle navigation when `currentFeedItemKey` changes.
  useEffect(() => {
    const index = items.findIndex(item => keyForResource(item) === currentFeedItemKey)
    if (index !== -1 && index !== currentUrlIdx) {
      setCurrentUrlIdx(index)
    }
  }, [currentFeedItemKey, items, currentUrlIdx])

  // Scroll to the current item when currentUrlIdx changes (if not caused by user scrolling).
  useEffect(() => {
    if (!isUserScrollingRef.current && containerRef.current && itemRefs.current[currentUrlIdx - startIndex]) {
      itemRefs.current[currentUrlIdx - startIndex]?.scrollIntoView({ behavior: 'smooth' })
    } else {
      isUserScrollingRef.current = false
    }
  }, [currentUrlIdx, startIndex])

  // Intersection observer.
  useEffect(() => {
    const observerOptions = {
      root: containerRef.current,
      threshold: 0.5,
    }

    const observerCallback = (entries: IntersectionObserverEntry[]) => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          const index = itemRefs.current.findIndex(ref => ref === entry.target)
          if (index !== -1) {
            const globalIndex = startIndex + index
            if (globalIndex !== currentUrlIdx) {
              isUserScrollingRef.current = true
              setCurrentUrlIdx(globalIndex)
              const newItem = itemsRef.current[globalIndex]
              if (newItem.type === 'url') {
                const { provider } = resolveMedia(newItem)
                if (ios) dispatch(setIsAutoMuted(provider === 'youtube'))
                history.pushState({ as: `/!${newItem.url_id}` }, '', `/!${newItem.url_id}`)
              }

              const newKey = keyForResource(newItem)?.toString()
              if (newKey) dispatch(setCurrentFeedItemKey(newKey))
            }
          }
        }
      })
    }

    const observer = new IntersectionObserver(observerCallback, observerOptions)

    itemRefs.current.forEach(ref => {
      if (ref) observer.observe(ref)
    })

    return () => {
      observer.disconnect()
    }
  }, [visibleItems, dispatch, ios, startIndex, currentUrlIdx])

  // Fetch more items when nearing the end of the list.
  useEffect(() => {
    if (items.length - currentUrlIdx <= NEAR_END_THRESHOLD) {
      dispatch(incrementPage(1))
    }
  }, [items.length, currentUrlIdx, dispatch])

  // Scroll to the initial item on mount.
  useEffect(() => {
    if (containerRef.current && itemRefs.current[currentUrlIdx - startIndex]) {
      itemRefs.current[currentUrlIdx - startIndex]?.scrollIntoView({ behavior: 'auto' })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div className="absolute inset-0 z-10 h-dvh w-full">
      <div className="size-full snap-y snap-mandatory overflow-y-auto scrollbar-hide" ref={containerRef}>
        {visibleItems.map((item, index) => (
          <div
            key={keyForResource(item)}
            ref={el => {
              itemRefs.current[index] = el
            }}
            className="h-dvh w-full snap-start"
          >
            {item.type === 'url' ? (
              <CurrentUrlContextProvider currentUrl={item}>
                <Url url={item} isActive={currentUrlIdx === startIndex + index} />
              </CurrentUrlContextProvider>
            ) : (
              <LoginSlide />
            )}
          </div>
        ))}
      </div>
    </div>
  )
}

export default memo(VerticalFeed)
