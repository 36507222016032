import { ForwardedRef, forwardRef, memo, useCallback, useRef } from 'react'
import ShowMoreText from 'react-show-more-text'

import { useTrackClick } from '@hooks/analytics/useTrackClick'
import { useIsArticle } from '@hooks/useIsArticle'
import IconArticle from '@icons/IconArticle.svg'
import { IntoUrl } from '@models/IntoUrl'

const MAX_LINES = 2
const DESCRIPTION_WIDTH_PERCENTAGE = 0.73

const UrlDescription = memo(
  forwardRef(({ url }: { url?: IntoUrl }, ref: ForwardedRef<HTMLDivElement>) => {
    const description = url?.description
    const descriptionRef = useRef<HTMLDivElement>(null)
    const isArticle = useIsArticle(url)

    const width = typeof window !== 'undefined' ? window.innerWidth * DESCRIPTION_WIDTH_PERCENTAGE : 0

    if (!description) return null

    return (
      <div ref={ref}>
        <ShowMoreText
          lines={MAX_LINES}
          more="more"
          less="less"
          width={width}
          truncatedEndingComponent={'... '}
          anchorClass="font-semibold"
          className="pointer-events-auto text-sm font-medium text-white/85"
        >
          <div ref={descriptionRef}>{description}</div>
        </ShowMoreText>
        {url && isArticle && <ReadArticleButton url={url} />}
      </div>
    )
  })
)

const ReadArticleButton = ({ url }: { url: IntoUrl }) => {
  const trackClick = useTrackClick()

  const handleCardClick = useCallback(() => {
    trackClick('urlOpenExternal')
    window.open(url.url, '_blank', 'noopener,noreferrer')
  }, [trackClick, url.url])

  return (
    <button
      onClick={handleCardClick}
      className="pointer-events-auto mt-3 flex w-fit items-center space-x-2 rounded-full bg-white/10 px-3 py-2 text-xs font-medium text-primary"
    >
      <IconArticle className="size-4" />
      <span>{url.meta?.provider?.openButtonTitleLong || 'Read Article'}</span>
    </button>
  )
}

export default UrlDescription
