import React from 'react'

const DEFAULT_SIZE = 24

export enum CaretDirection {
  Up = 'up',
  Down = 'down',
  Left = 'left',
  Right = 'right',
}

interface IconCaretProps {
  direction?: CaretDirection
  size?: number
  className?: string
}

const IconCaret: React.FC<IconCaretProps> = ({ size = DEFAULT_SIZE, direction = CaretDirection.Down, className }) => {
  const rotations: Record<CaretDirection, number> = {
    [CaretDirection.Up]: 180,
    [CaretDirection.Down]: 0,
    [CaretDirection.Left]: -90,
    [CaretDirection.Right]: 90,
  }

  const rotation = rotations[direction]

  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      style={{ transform: `rotate(${rotation}deg)` }}
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M22 7.00001L12.9999 16C12.4477 16.5522 11.5522 16.5522 11 16L2 7"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
      />
    </svg>
  )
}

export default IconCaret
