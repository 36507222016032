import React from 'react'

import IconSpeaker from '@icons/IconSpeaker.svg'
import IconUnmute from '@icons/IconUnmute.svg'
import { setIsMuted } from '@redux/slices/videoSlice'
import { useAppDispatch, useAppSelector } from '@redux/store/store'

export const VolumeControl: React.FC = () => {
  const dispatch = useAppDispatch()
  const { isAutoMuted, isMuted } = useAppSelector(state => state.video)

  const handleToggleMute = (ev: React.TouchEvent | React.MouseEvent) => {
    ev.preventDefault()
    ev.stopPropagation()
    dispatch(setIsMuted(!isMuted))
  }

  return (
    <div
      className={`pointer-events-auto relative flex size-10 flex-row items-center justify-between gap-0 rounded-3xl pl-0 transition-width duration-500`}
    >
      <button
        className="mr-2 flex size-6 items-center justify-center focus:outline-none"
        onMouseDown={handleToggleMute}
        onTouchStart={handleToggleMute}
        onTouchEnd={handleToggleMute}
        onMouseUp={handleToggleMute}
      >
        {isMuted || isAutoMuted ? (
          <IconUnmute className="size-6 text-white" />
        ) : (
          <IconSpeaker className="size-6 text-white" />
        )}
      </button>
    </div>
  )
}
