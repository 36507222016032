import { DAY_TO_MS } from '@lib/time-constants'

export const curatorCount = () => {
  const DEFAULT_CURATOR_COUNT = 12691
  const DEFAULT_CURATOR_COUNT_TIMESTAMP = 1651092248269
  const AVG_DAILY_NEW_USERS = 109
  const daysSinceStamp = Math.floor((Date.now() - DEFAULT_CURATOR_COUNT_TIMESTAMP) / DAY_TO_MS)
  // Add average number of daily new users based on query
  return (DEFAULT_CURATOR_COUNT + AVG_DAILY_NEW_USERS * daysSinceStamp).toLocaleString('en-US')
}
